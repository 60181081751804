body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container-row {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.fixed {
  position: fixed;
}
.z-20 {
  z-index: 20;
}
.ant-card-head {
  border: none !important;
}
.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.p-2 {
  padding: 0.5rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.ant-drawer-footer {
  padding: 10px 16px;
}
.ant-menu-submenu {
  padding-bottom: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 0.25rem !important;
}
.m-4 {
  margin: 1rem !important;
}
.mx-2 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-3 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mr-2 {
  margin-right: 0.25rem;
}
.mr-3 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.px-2 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-3 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.bold {
  font-weight: bold;
}

/* xs: '480px',
sm: '576px',
md: '768px',
lg: '992px',
xl: '1200px',
xxl: '1600px', */

.d-flex {
  display: flex;
}

.logo {
  min-width: 250px;
}

.min-h-100vh {
  min-height: 100vh;
}

.mt-64 {
  margin-top: 64px;
}

.d-none {
  display: none !important;
}

@media (min-width: 300px) {
  .d-none-xs {
    display: none;
  }
  .hide-mobile {
    display: none;
  }
}

@media (max-width: 575px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .d-none-sm {
    display: none;
  }

  .hide-mobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .d-none-md {
    display: none;
  }
  .d-flex-md {
    display: flex;
  }
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .d-none-lg {
    display: none;
  }
  .d-flex-lg {
    display: flex;
  }
  .hide-mobile {
    display: inherit;
  }
}

@media (min-width: 1200px) {
  .d-none-xl {
    display: none;
  }
  .hide-mobile {
    display: inherit;
  }
}

@media (min-width: 1600px) {
  .d-none-xxl {
    display: none;
  }
  .hide-mobile {
    display: inherit;
  }
}
.fc-event {
  color: #fff;
}
.fc-daygrid-day {
  margin: 10px !important;
  border: none !important;
  border-top: 2px solid rgba(253, 253, 253, 0.12) !important;
}
.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  color: #d8d8d8 !important;
}
th.fc-col-header-cell {
  text-align: right !important;
  border-bottom: 2px solid rgba(253, 253, 253, 0.12) !important;
}
.fc-day-today {
  background: rgba(49, 49, 49, 0.2) !important;
}
.ant-picker-cell-range-hover::before {
  background: rgba(253, 253, 253, 0.12) !important;
}
.ant-picker-cell-range-hover-end::before {
  background: rgba(253, 253, 253, 0.12) !important;
}
.ant-picker-cell-range-hover-end .ant-picker-cell-inner * {
  background: yellow !important;
}

:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #141414 !important;
  --fc-border-color: none !important;
  --fc-event-text-color: #fff !important;
  --fc-event-selected-overlay-color: rgba(49, 49, 49, 0.5) !important;

  --fc-more-link-bg-color: #d0d0d0 !important;
  --fc-more-link-text-color: inherit !important;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-highlight-color: rgba(49, 49, 49, 0.3) !important;
  --fc-today-bg-color: rgba(205, 205, 205, 0.15) !important;
  --PhoneInput-color--focus: #424242 !important;
}

/* Phone number */
input[type='tel' i] {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

input[type='tel' i]:focus {
  border-color: #4096ff;
  box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
  border-inline-end-width: 1px;
  outline: 0;
}

.fc-timegrid-body tr {
  border: 1px solid rgb(58, 58, 58) !important;
}

.fc .fc-timegrid-slot-minor {
  border-top: 1px solid rgb(28, 28, 28) !important;
}

.fc-timegrid-body td {
  border: 1px solid rgb(47, 47, 47) !important;
}

.fc-timegrid th.fc-col-header-cell {
  text-align: center !important;
  padding-bottom: 10px;
}

.ant-drawer,
.ant-drawer-open {
  outline: none !important;
}
.ant-timeline-item-last {
  padding: 0 !important;
  margin: 0 !important;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: fit-content !important;
}
input[type='tel'] {
  background: #1e1e1e;
  border: 1px solid #424242;
  color: #fff;
}

input[type='tel' i]:focus {
  border-color: #8796e8;
  box-shadow: 0 0 0 2px rgba(1, 1, 130, 0.14);
  border-inline-end-width: 1px;
  outline: 0;
}
.mobile-menu {
  border: none;
  width: 100%;
  justify-content: space-between;
}
.mobile-menu::before {
  content: none;
}
.mobile-menu::after {
  content: none;
}

.desktop-menu {
  border: none;
  width: 100%;
}

.invoices-table tr {
  cursor: pointer;
}

li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected {
  color: black;
}
